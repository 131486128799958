// TODO: These variables *might* best belong in appConfig.ts
const privacyPolicyBaseUrl = 'https://www.cawst.org/privacy-policy-and-terms';
const termsOfUseFragment = '#terms-of-usage-page';
const languageQueryParameter = 'lang';

export function useLegalUrls(): {
  termsOfUseUrl: Ref<string>;
  privacyPolicyUrl: Ref<string>;
} {
  const { locale } = useI18n();

  const termsOfUseUrl = computed(
    () => `${privacyPolicyBaseUrl}?${languageQueryParameter}=${locale.value}${termsOfUseFragment}`
  );
  const privacyPolicyUrl = computed(() => `${privacyPolicyBaseUrl}?${languageQueryParameter}=${locale.value}`);

  return {
    termsOfUseUrl,
    privacyPolicyUrl,
  };
}
