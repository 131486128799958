<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
const { t } = useI18n();
</script>

<template>
  <div class="bg-cawstNavy py-16 md:py-20">
    <div class="container grid grid-cols-1 gap-12 md:grid-cols-2 lg:gap-x-20">
      <div class="flex flex-col justify-between">
        <div class="flex flex-col">
          <h2 class="h4 pt-0 text-white">{{ t('footer.about.title') }}</h2>
          <p class="details-1 mb-5 mt-2 text-white text-opacity-70">{{ t('footer.about.details') }}</p>
          <div class="flex justify-start">
            <a href="https://www.cawst.org/" target="_blank" rel="noopener" class="btn btn-ghost dark">
              <ArrowTopRightOnSquareIcon class="btn__icon stroke-2" />
              {{ t('footer.about.button') }}
            </a>
          </div>
        </div>
        <div class="hidden md:block">
          <FooterNav />
        </div>
      </div>
      <div class="flex flex-col">
        <h2 class="h4 pt-0 text-white">{{ t('footer.usaid.title') }}</h2>
        <p class="details-1 mb-2 mt-2 text-white text-opacity-70">{{ t('footer.usaid.details_1') }}</p>
        <p class="details-1 mb-5 text-white text-opacity-70">{{ t('footer.usaid.details_2') }}</p>
        <div class="flex justify-start md:mb-3">
          <img
            class="h-16"
            :alt="t('footer.usaid.altText')"
            src="https://res.cloudinary.com/db8eidwqd/image/upload/v1635291307/WASH%20Resources/Logos/USAID-Horizontal_White_Vector_jqvqek.svg"
          />
        </div>
      </div>
      <div class="md:hidden">
        <FooterNav />
      </div>
    </div>
  </div>
</template>
