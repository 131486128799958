<script setup lang="ts">
const { t } = useI18n();
const { termsOfUseUrl, privacyPolicyUrl } = useLegalUrls();
const localePath = useLocalePath();

const navItems = computed(() => [
  { label: t('navigation.items.home'), to: localePath('/') },
  {
    label: t('navigation.items.explore'),
    to: localePath({ path: '/explore' }),
  },
  { label: t('navigation.items.search'), to: localePath({ path: '/search' }) },
]);

const navSubItems = computed(() => [
  {
    label: t('navigation.items.termsOfUse'),
    href: termsOfUseUrl.value,
  },
  {
    label: t('navigation.items.privacyPolicy'),
    href: privacyPolicyUrl.value,
  },
]);
</script>

<template>
  <div class="row space-x-4 sm:space-x-0">
    <!-- TODO: If already on the current page, scrolls to top -->
    <NuxtLink v-for="item in navItems" :key="item.label" :to="item.to" class="nav-link--footer">
      {{ item.label }}
    </NuxtLink>
  </div>
  <div class="row flex justify-start divide-x divide-white divide-opacity-20">
    <a
      v-for="(item, index) in navSubItems"
      :key="index"
      :href="item.href"
      target="_blank"
      rel="noreferrer"
      class="details-2 px-3 text-white text-opacity-50 hover:text-white hover:text-opacity-60 hover:no-underline"
    >
      {{ item.label }}
    </a>
  </div>
</template>

<style>
.nav-link--footer {
  @apply inline-flex items-center rounded-md px-3 pb-2 pt-1 text-sm uppercase tracking-widest text-white text-opacity-90 transition-all duration-200 hover:text-white hover:text-opacity-100 hover:no-underline focus:bg-white focus:bg-opacity-10 focus:outline-none;
}
</style>
