<script setup lang="ts">
import {
  GlobeAltIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  Bars3Icon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/vue/24/outline';
import { ChevronDownIcon } from '@heroicons/vue/16/solid';

const props = withDefaults(
  defineProps<{
    // TODO: Language refresh is a solution for squelching errors when changing Algolia search indices
    forceLanguageRefresh?: boolean;
    hideSearch?: boolean;
    mode?: string;
  }>(),
  {
    forceLanguageRefresh: false,
    hideSearch: false,
    mode: 'raised',
  }
);

const { $viewport } = useNuxtApp();
const { signIn: authLogIn, signOut: authLogOut, session: authSession, status: authStatus } = useAuth();
const { t, locales, locale: localeCode, localeProperties } = useI18n();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();

const mixpanel = useMixpanel();

const { termsOfUseUrl, privacyPolicyUrl } = useLegalUrls();

const searchBarIsVisible = ref(false);
const searchTerms = ref('');

const navItems = computed(() => [
  { label: t('navigation.items.home'), to: localePath('/') },
  { label: t('navigation.items.explore'), to: localePath({ path: '/explore' }) },
  { label: t('navigation.items.search'), to: localePath({ path: '/search' }) },
]);
const navSubItems = computed(() => [
  {
    label: t('navigation.items.termsOfUse'),
    href: termsOfUseUrl.value,
  },
  {
    label: t('navigation.items.privacyPolicy'),
    href: privacyPolicyUrl.value,
  },
]);

const isAuthenticated = computed(() => {
  return authStatus.value === 'authenticated';
});

const userEmail = computed(() => {
  return authSession.value?.user?.email || '';
});

const userName = computed(() => {
  return authSession.value?.user?.name || '';
});

const userAvatarUrl = computed(() => {
  return authSession.value?.user?.image || '';
});

const currentLocaleName = computed(() => {
  return localeProperties.value.name || localeCode.value.toUpperCase();
});

function setLocale(localeCode: string): void {
  const localizedPath = switchLocalePath(localeCode);
  if (!props.forceLanguageRefresh) {
    navigateTo(switchLocalePath(localeCode));
  } else {
    reloadNuxtApp({
      path: localizedPath,
    });
  }
}

function signIn(): void {
  authLogIn('auth0');
}

function signOut(): void {
  mixpanel.reset();
  authLogOut();
}

function submitSearch(closeDisclosurePanel?: () => void): void {
  if (closeDisclosurePanel) {
    closeDisclosurePanel();
  }
  const sanitizedSearchTerms = removeExtraWhitespace(searchTerms.value);
  searchBarIsVisible.value = false;
  searchTerms.value = '';
  navigateTo(
    localePath({
      path: '/search',
      query: { query: sanitizedSearchTerms },
    })
  );
}
function toLocaleCode(locale: any /* string | LocaleObject - LocaleObject typing is no longer exported */): string {
  return typeof locale === 'string' ? locale : locale.code;
}

function toLocaleName(
  locale: any /* string | LocaleObject - LocaleObject typing is no longer exported */
): string | undefined {
  return typeof locale === 'string' ? locale : locale.name;
}

function toggleSearchBar(): void {
  searchBarIsVisible.value = !searchBarIsVisible.value;
}
</script>

<template>
  <ClientOnly>
    <Disclosure v-slot="{ open }" as="nav" class="navbar relative" :class="mode">
      <div
        class="container relative"
        :class="open && $viewport.isLessThan('sm') && mode !== 'transparent' ? 'bg-white' : 'bg-transparent'"
      >
        <div class="flex justify-between" style="height: 4.5rem">
          <!-- Logo section -->
          <div
            class="z-40 mt-0.5 flex items-center justify-center"
            :class="open && $viewport.isLessThan('sm') ? 'fixed left-5' : 'px-1'"
            :style="open && $viewport.isLessThan('sm') ? 'top: 9px' : ''"
          >
            <TransitionRoot
              appear
              enter="transform transition ease-in-out duration-700"
              enter-from="opacity-0 -translate-x-2"
              enter-to="opacity-100 translate-x-0"
              show
            >
              <NuxtLink
                :to="localePath('/')"
                class="focus-visible:blue-ring -ml-4 -mt-2 flex flex-shrink-0 items-center pl-3 hover:no-underline sm:-mt-3 lg:-ml-3"
              >
                <img
                  class="h-14 w-auto py-3 sm:h-16"
                  src="https://res.cloudinary.com/db8eidwqd/image/upload/c_scale,h_40/v1623638486/WASH%20Resources/Logos/cawst_logo--horizontal-trans_t1ugcq.png"
                  :alt="t('navbar.srText.orgLogo')"
                />
                <div class="mx-3 mt-2 border-l border-cawstNavy px-3 py-0.5 text-cawstBlue sm:mt-3">
                  {{ t('navigation.title') }}
                </div>
              </NuxtLink>
            </TransitionRoot>
          </div>

          <!-- Navigation section -->
          <div class="md:absolute-center hidden md:flex md:h-full md:space-x-4 lg:space-x-8">
            <NuxtLink
              v-for="(item, index) in navItems"
              :key="index"
              exact
              :to="item.to"
              class="nav-tab focus-visible:blue-ring text-grey-dark"
              active-class="hover:cursor-not-allowed transition-all duration-200"
              exact-active-class="font-bold"
            >
              {{ item.label }}
            </NuxtLink>
          </div>

          <!-- Right-aligned navbar items -->
          <div class="row hidden items-center md:flex">
            <!-- Search toggle -->
            <div v-if="!hideSearch" class="nav-link">
              <button
                type="button"
                class="btn-text nav-link flex items-center rounded-full p-3"
                aria-expanded="true"
                aria-haspopup="true"
                @click="toggleSearchBar"
              >
                <span class="sr-only">{{ t('navbar.srText.icons.search') }}</span>
                <MagnifyingGlassIcon class="h-6 w-6 stroke-2 text-gray-500" />
              </button>
            </div>

            <!-- Sign-in toggle / User dropdown menu -->
            <Menu v-slot="{ open: userMenuOpen }" as="div" class="relative inline-block text-left">
              <div>
                <MenuButton
                  v-if="isAuthenticated"
                  id="user-menu-button"
                  type="button"
                  class="nav-link btn-text inline-flex items-center justify-center"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span class="sr-only">{{ t('navbar.srText.openUserMenu') }}</span>
                  <img
                    class="ph-no-capture mr-1 h-8 w-8 rounded-full lg:mr-2"
                    :src="userAvatarUrl"
                    :alt="userName"
                    referrerpolicy="no-referrer"
                  />
                  <span class="ph-no-capture hidden max-w-56 truncate lg:inline-block">{{ userEmail }}</span>
                  <ChevronDownIcon
                    class="h-5 w-5 stroke-2 transition-all duration-300 ease-in-out lg:hidden"
                    :class="{ 'rotate-180': userMenuOpen }"
                  />
                </MenuButton>
                <button v-else class="nav-link btn-text" @click="signIn">
                  {{ t('navbar.items.signIn') }}
                </button>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="px-4 py-3">
                    <p class="mb-0 text-sm">
                      {{ t('navbar.items.signedInAs') }}
                    </p>
                    <p class="ph-no-capture mb-0 truncate text-sm font-bold text-gray-900">
                      {{ userEmail }}
                    </p>
                  </div>
                  <div class="py-1">
                    <MenuItem>
                      <button type="submit" class="nav-dropdown__item text-cawstBlue" @click="signOut">
                        {{ t('navbar.items.signOut') }}
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <!-- Language toggle / dropdown menu -->
            <Menu v-slot="{ open: languageMenuOpen }" as="div" class="relative inline-block text-left">
              <div>
                <MenuButton class="nav-link btn-text inline-flex items-center justify-center">
                  <span class="sr-only">{{ t('navbar.srText.changeLanguage') }}</span>
                  <GlobeAltIcon class="mr-2 h-6 w-6" />
                  {{ currentLocaleName }}
                  <ChevronDownIcon
                    class="mx-1 h-5 w-5 transition-all duration-300 ease-in-out"
                    :class="{ 'rotate-180': languageMenuOpen }"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1">
                    <MenuItem
                      v-for="locale in locales"
                      v-slot="{ active }"
                      :key="toLocaleCode(locale)"
                      :disabled="toLocaleCode(locale) === localeCode"
                    >
                      <button
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'nav-dropdown__item',
                          toLocaleCode(locale) === localeCode ? 'disabled' : '',
                        ]"
                        @click="setLocale(toLocaleCode(locale))"
                      >
                        {{ toLocaleName(locale) }}
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <div class="flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="focus-visible:blue-ring z-40 inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-200 hover:text-gray-500"
              :class="open && $viewport.isLessThan('sm') ? 'fixed right-0 ml-2 mr-2' : '-mr-2'"
            >
              <span class="sr-only">{{ t('navbar.srText.openMainMenu') }}</span>
              <transition
                enter-active-class="transition transform ease-out duration-300"
                enter-from-class="opacity-0 -rotate-90"
                enter-to-class="transform opacity-100 rotate-0"
                leave-active-class="transition transform ease-in duration-300"
                leave-from-class="opacity-100 rotate-0"
                leave-to-class="transform opacity-0 rotate-90"
                mode="out-in"
              >
                <Bars3Icon v-if="!open" class="h-6 w-6 stroke-2" />
                <XMarkIcon v-else class="h-6 w-6 stroke-2" />
              </transition>
            </DisclosureButton>
          </div>
        </div>
      </div>

      <!-- Expanding search section -->
      <form class="hidden md:block" @submit.prevent="() => submitSearch()">
        <transition
          enter-active-class="transition transform ease-out duration-300"
          enter-from-class="opacity-0 -translate-y-10"
          enter-to-class="transform opacity-100 translate-y-0"
          leave-active-class="transition transform ease-in duration-300"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="transform opacity-0 -translate-y-10"
        >
          <div v-if="searchBarIsVisible" class="container pb-6 pt-3">
            <div class="row flex items-center">
              <label for="search" class="sr-only">{{ t('searchBar.button') }}</label>
              <div class="relative flex-grow">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
                  <MagnifyingGlassIcon class="h-5 w-5 stroke-2 text-gray-500" />
                </div>
                <input
                  id="search"
                  v-model="searchTerms"
                  name="search"
                  class="block w-full rounded-l-full border py-3 pl-14 pr-3 placeholder-gray-500 focus:border-cawstBlue focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-cawstBlue"
                  :class="mode === 'transparent' ? 'border-white bg-white' : 'border-grey-light bg-grey-light'"
                  :placeholder="
                    $viewport.isLessThan('sm') ? t('searchBar.placeholder.mobile') : t('searchBar.placeholder.desktop')
                  "
                  type="search"
                />
              </div>
              <button class="btn btn-large btn-primary rounded-r-full py-3">
                {{ t('searchBar.button') }}
              </button>
            </div>
          </div>
        </transition>
      </form>

      <!-- Mobile menu -->
      <transition
        enter-active-class="transition transform ease-out duration-300"
        enter-from-class="opacity-0 -translate-y-5"
        enter-to-class="transform opacity-100 translate-y-0"
        leave-active-class="transition transform ease-in duration-50"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="transform opacity-0 -translate-y-5"
      >
        <DisclosurePanel
          v-slot="{ close }"
          class="fixed left-0 top-0 z-30 flex h-screen w-full flex-grow flex-col overflow-auto overscroll-contain border-t-[4.5rem] border-t-white bg-white md:hidden"
        >
          <!-- Mobile search section -->
          <form v-if="!hideSearch" @submit.prevent="() => submitSearch(close)">
            <div class="container mt-8 flex items-center lg:hidden">
              <label for="search" class="sr-only">{{ t('searchBar.button') }}</label>
              <div class="relative flex-grow">
                <input
                  id="mobile-search"
                  v-model="searchTerms"
                  name="search"
                  class="focus:blue-ring block w-full rounded-l-full border border-grey-light bg-grey-light py-2 pl-6 pr-3 placeholder-gray-500 focus:border-cawstBlue focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none"
                  :placeholder="t('searchBar.placeholder.mobile')"
                  type="search"
                />
              </div>
              <button class="btn btn-large btn-primary rounded-r-full">
                <MagnifyingGlassIcon class="-ml-0.5 h-6 w-6 stroke-2" />
                <span class="sr-only">{{ t('searchBar.button') }}</span>
              </button>
            </div>
          </form>

          <!-- Main menu items -->
          <div class="container my-12 flex flex-col space-y-3">
            <NuxtLink
              v-for="(item, index) in navItems"
              :key="index"
              exact
              :to="item.to"
              class="nav-tab focus-visible:blue-ring text-xl font-medium text-grey-darkest hover:text-cawstBlue"
              active-class="hover:cursor-not-allowed hover:text-grey-darkest transition-all duration-200"
              exact-active-class="font-bold"
              @click="close"
            >
              {{ item.label }}
            </NuxtLink>
            <Menu v-slot="{ open: languageMenuOpen }" as="div" class="relative inline-block text-left">
              <div>
                <MenuButton class="nav-tab w-full text-xl text-grey-darkest hover:text-cawstBlue">
                  {{ t('navigation.items.language') }}
                  <ChevronDownIcon
                    class="mx-1 mt-1 h-5 w-5 transition-all duration-300 ease-in-out"
                    :class="{ 'rotate-180': languageMenuOpen }"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-300"
                enter-from-class="transform opacity-0 -translate-x-5"
                enter-to-class="transform opacity-100 translate-x-0"
                leave-active-class="transition ease-in duration-300"
                leave-from-class="transform opacity-100 translate-x-0"
                leave-to-class="transform opacity-0 -translate-x-5"
              >
                <MenuItems class="mt-2">
                  <div class="py-1">
                    <MenuItem
                      v-for="locale in locales"
                      v-slot="{ active }"
                      :key="typeof locale === 'string' ? locale : locale.code"
                      :disabled="(typeof locale === 'string' ? locale : locale.code) === localeCode"
                    >
                      <button
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'nav-dropdown__item',
                          (typeof locale === 'string' ? locale : locale.code) === localeCode ? 'disabled' : '',
                        ]"
                        @click="setLocale(typeof locale === 'string' ? locale : locale.code)"
                      >
                        {{ typeof locale === 'string' ? locale : locale.name }}
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <!-- Secondary menu items -->
          <div class="container flex flex-col space-y-3">
            <a
              v-for="(item, index) in navSubItems"
              :key="index"
              :href="item.href"
              target="_blank"
              rel="noreferrer"
              class="nav-tab text-sm text-grey-dark hover:text-cawstBlue"
              active-class="hover:cursor-not-allowed hover:text-grey-darkest transition-all duration-200"
              exact-active-class="font-bold"
              @click="close"
            >
              {{ item.label }}
              <ArrowTopRightOnSquareIcon class="btn__icon mx-1 h-4 w-4 stroke-2" />
            </a>
            <a href="https://www.cawst.org/contact" class="nav-tab text-sm text-grey-dark hover:text-cawstBlue">
              {{ t('navigation.items.contactUs') }}
              <ArrowTopRightOnSquareIcon class="btn__icon mx-1 h-4 w-4 stroke-2" />
            </a>
          </div>

          <div class="flex flex-grow items-end">
            <div v-if="isAuthenticated" class="container flex items-center border-t border-gray-200 py-6">
              <div class="flex-shrink-0 px-0.5">
                <img
                  class="ph-no-capture h-10 w-10 rounded-full"
                  :src="userAvatarUrl"
                  :alt="userName"
                  referrerpolicy="no-referrer"
                />
              </div>
              <button class="flex w-full flex-col px-3" @click="signOut">
                <span class="ph-no-capture text-base font-medium text-gray-800">{{ userEmail }}</span>
                <span class="text-sm font-medium text-gray-500">{{ t('navbar.items.signOut') }}</span>
              </button>
            </div>
            <div v-else class="container flex items-center py-6">
              <button class="btn btn-xlarge btn-primary w-full" @click="signIn">
                {{ t('navbar.items.signInOrRegister') }}
              </button>
            </div>
          </div>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.navbar {
  .expand-enter-active,
  .expand-leave-active {
    transition-duration: 200ms;
  }
}

.navbar.raised {
  @apply bg-white shadow;
}

.navbar.transparent {
  @apply /* Currently styled for landing page */ bg-grey-light transition-all duration-200;
}

.nav-tab {
  @apply inline-flex items-center px-3 pb-2 pt-1 hover:text-cawstBlue-dark hover:no-underline focus:outline-none;
}

.nav-tab.router-link-exact-active {
  @apply text-gray-900;
}

.nav-tab.router-link-exact-active:hover {
  @apply cursor-default;
}

.nav-link {
  @apply h-full p-3 text-grey-dark hover:text-cawstBlue-dark hover:no-underline;
}

.nav-dropdown {
  @apply absolute right-0 w-56 origin-top-right rounded-md border border-grey-light bg-white py-1 shadow-lg;
}

.nav-dropdown__item {
  @apply block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 hover:no-underline;
}
</style>
